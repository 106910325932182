import React from "react";
import "./results.css";

const NoResults = ({ inResults, firmResults, loading, initialPage }) => {

	// if there are individual results, firm results, the app is searching or no results have been asked for then do not display

	if (inResults || firmResults ||  loading || initialPage ) {
		return null;
	}

	return (
		<div aria-label="no results found" id="noResultsContainer">
			<h4>
				Sorry, there have been no results for your enquiry. Please return to the
				search form to search again.
			</h4>
		</div>
	);
};

export default NoResults;
