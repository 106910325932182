/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import "./FormContainer.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { TraceSpinner } from "react-spinners-kit";
import OrganisationForm from "../components/OrganisationForm";
import IndividualForm from "../components/IndividualForm";
import IndividualResults from "../components/IndividualResults";
import OrganisationResults from "../components/OrganisationResults";
import NoResults from "../components/NoResults";


export default class FormContainer extends Component {

	static handleClick(event) {
		const headerId = event.currentTarget.id;
		const card = document.getElementById(`${headerId}Card`);
		const details = card.getElementsByClassName("toggleText");
		if (!card.classList.contains("expanded")) {
			card.classList.add("expanded");
			return details[0].classList.remove("hidden");
		}
		details[0].classList.add("hidden");
		return card.classList.remove("expanded");
	}

	static capitaliseAndTrimSubmissions(submission) {
		const trimmedSubmission = submission.trim();
		return (
			trimmedSubmission.charAt(0).toUpperCase() + trimmedSubmission.slice(1)
		);
	}

	static organiseIndividualResults(a, b) {
		const firstResult = a.LastName.toLowerCase();
		const secondResult = b.LastName.toLowerCase();
		let organise = 1;
		if (firstResult < secondResult) {
			organise = -1
		}
		return organise;
	}

	static organiseOrganisationResults(a, b) {
		const firstName = a.Name.toLowerCase();
		const firstTown = a.Town.toLowerCase();
		const secondName = b.Name.toLowerCase();
		const secondTown = b.Town.toLowerCase();
		let organise;
		if (firstName === secondName) {
			if (firstTown < secondTown) organise = -1;
			if (firstTown > secondTown) organise = 1;
		} else {
			if (firstName < secondName) organise = -1;
			if (firstName > secondName) organise = 1;
		}
		return organise;
	}

	constructor(props) {
		super(props);
		this.state = {
			FirstName: "",
			Surname: "",
			Location: "",
			Results: [],
			sortedIndividualResults: null,
			sortedOrganisationResults: null,
			activeResultsPage: 0,
			startPaginationIndex: 0,
			endPaginationIndex: 5,
			findingCA: false,
			initialPage: true
		};
		this.handleIndividualSubmit = this.handleIndividualSubmit.bind(this);
		this.handleOrganisationSubmit = this.handleOrganisationSubmit.bind(this);
		// FormContainer.handleClick = FormContainer.handleClick.bind(this);
		this.handleTabClick = this.handleTabClick.bind(this);
		this.handlePaginationClick = this.handlePaginationClick.bind(this);
		this.sortResultsToDisplay = this.sortResultsToDisplay.bind(this);
	}

	handleOrganisationSubmit(event) {
		event.preventDefault();
		const errorMessage = document.getElementById("hiddenFirmErrorMessage");
		errorMessage.classList.add("hiddenError");
		const form = event.target;
		const inputs = {};
		if (form.elements.companyName.value)
			inputs.Name = FormContainer.capitaliseAndTrimSubmissions(
				form.elements.companyName.value
			);
		if (form.elements.location.value)
			inputs.Location = FormContainer.capitaliseAndTrimSubmissions(
				form.elements.location.value
			);
		if (form.elements.auditRegisteredFirm.checked)
			inputs.IsRegisteredForAuditWork =
				form.elements.auditRegisteredFirm.checked;
		if (form.elements.ipFirm.checked)
			inputs.HasLicensedInsolvencyPractitioners = form.elements.ipFirm.checked;
		if (form.elements.investmentBAFirm.checked)
			inputs.IsRegulatedForInvestmentActivities =
				form.elements.investmentBAFirm.checked;
		this.displayLoadingScreen(form);
		this.getFirms(inputs);
		document.getElementById("hiddenButton").classList.remove("hidden");
	}

	handleIndividualSubmit(event) {
		event.preventDefault();
		const errorMessage = document.getElementById("hiddenErrorMessage");
		errorMessage.classList.add("hiddenError");
		const form = event.target;
		const inputs = {};
		if (form.elements.forename.value)
			inputs.Forename = FormContainer.capitaliseAndTrimSubmissions(
				form.elements.forename.value
			);
		if (form.elements.surname.value)
			inputs.Surname = FormContainer.capitaliseAndTrimSubmissions(
				form.elements.surname.value
			);
		if (form.elements.location.value)
			inputs.Location = FormContainer.capitaliseAndTrimSubmissions(
				form.elements.location.value
			);
		if (form.elements.ipCheckbox.checked)
			inputs.HasInsolvencyPermit = form.elements.ipCheckbox.checked;
		if (form.elements.pcCheckbox.checked)
			inputs.HasPractisingCertificate = form.elements.pcCheckbox.checked;

		if (Object.keys(inputs).length < 2) {
			errorMessage.classList.remove("hiddenError");
			errorMessage.classList.add("shownError");
			return;
		}
		this.displayLoadingScreen(form);
		this.getMembers(inputs);
		document.getElementById("hiddenButton").classList.remove("hidden");
	}

	handleTabClick() {
		this.setState({
			sortedIndividualResults: null,
			sortedOrganisationResults: null,
			findingCA: false,
			initialPage: true
		});
		document.getElementById("hiddenButton").classList.add("hidden");
		const errorMessage = document.getElementById("hiddenErrorMessage");
		if (!errorMessage.classList.contains("hiddenError")) {
			errorMessage.classList.add("hiddenError");
		}
		[...document.getElementsByClassName("optionForm")].forEach((form) => {
			if (form.classList.contains("hidden")) {
				form.classList.remove("hidden");
			}
		});
		return true;
	}

	handlePaginationClick(page) {
		const pageNumber = page.selected;
		const startIndex = pageNumber * 5;
		const endIndex = startIndex + 5;
		this.setState(
			{
				activeResultsPage: pageNumber,
				startPaginationIndex: startIndex,
				endPaginationIndex: endIndex,
			},
			() => this.sortResultsToDisplay()
		);
	}

	async getMembers(searchCriteria) {
		await axios
			.post(process.env.REACT_APP_BASE_URL+"/members/info", searchCriteria, { /// TODO: to use URL from Tokens
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				this.setState(
					{
						Results: response.data,
						startPaginationIndex: 0,
						endPaginationIndex: 5,
						activeResultsPage: 0,
					},
					() => this.sortResultsToDisplay("individual")
				);
			});
	}

	async getFirms(searchCriteria) {
		await axios
			.post(process.env.REACT_APP_BASE_URL+"/firms/info", searchCriteria, {/// TODO: to use URL from Tokens
				headers: {
					'Content-Type': 'application/json'
				}
			}) /// TODO: to use URL from Tokens
			.then((response) => {
				this.setState(
					{
						Results: response.data,
						startPaginationIndex: 0,
						endPaginationIndex: 5,
						activeResultsPage: 0,
					},
					() => this.sortResultsToDisplay("firm")
				);
			});
	}

	sortResultsToDisplay(type) {
		const {
			Results: results,
			startPaginationIndex,
			endPaginationIndex,
			sortedIndividualResults,
			sortedOrganisationResults
		} = this.state;
		let individualOrFirm = type;
		this.setState({ findingCA: false, initialPage: false });
		if (!results) {
			return this.setState({
				sortedOrganisationResults: null,
				sortedIndividualResults: null,
			});
		}
		if (!type && sortedIndividualResults) {
			individualOrFirm = "individual";
		}
		if (!type && sortedOrganisationResults) {
			individualOrFirm = "firm";
		}
		const { length } = results;

		switch (true) {
			case length === 0: {
				return this.setState({
					sortedOrganisationResults: null,
					sortedIndividualResults: null,
				});
			}
			case length <= 5: {
				return individualOrFirm === "individual"
					? this.setState({
						sortedIndividualResults: results.sort(
							FormContainer.organiseIndividualResults
						),
					})
					: this.setState({
						sortedOrganisationResults: results.sort(
							FormContainer.organiseOrganisationResults
						),
					});
			}
			case length > 5: {
				return individualOrFirm === "individual"
					? this.setState({
						sortedIndividualResults: results
							.sort(FormContainer.organiseIndividualResults)
							.slice(startPaginationIndex, endPaginationIndex),
					})
					: this.setState({
						sortedOrganisationResults: results
							.sort(FormContainer.organiseOrganisationResults)
							.slice(startPaginationIndex, endPaginationIndex),
					});
			}
			default:
				return false;
		}
	}

	displayLoadingScreen(form) {
		this.setState({ findingCA: true, });
		form.classList.add("hidden");
	}

	render() {
		const { sortedIndividualResults, sortedOrganisationResults, Results, findingCA, startPaginationIndex, initialPage } = this.state;

		return (
			<div className="main" aria-label="Find a CA">
				<h1 style={{ fontWeight: "300" }}>Find a Chartered Accountant (CA)</h1>
				<Tabs
					defaultActiveKey="member"
					id="formsTab"
					style={{ marginTop: "2%" }}
					onClick={this.handleTabClick}
					aria-label="CA Member Individual Search or CA Member Organisation Search Tab Options"
				>
					<Tab eventKey="member" title="Individuals">
						<IndividualForm submit={this.handleIndividualSubmit} />
					</Tab>
					<Tab eventKey="firms" title="Firms" onClick={this.handleTabClick}>
						<OrganisationForm submit={this.handleOrganisationSubmit} />
					</Tab>
				</Tabs>
				<NoResults
					inResults={sortedIndividualResults}
					firmResults={sortedOrganisationResults}
					loading={findingCA}
					initialPage={initialPage}
				/>
				<TraceSpinner
					size={250}
					frontColor="#0078BF"
					backColor="#F9B122"
					loading={findingCA}
				/>
				<Button
					variant="primary"
					className="hidden"
					id="hiddenButton"
					onClick={this.handleTabClick}
					style={{ marginTop: "4%" }}
				>
					Back to Search Form
				</Button>
				{Results ? <IndividualResults
					handleClick={FormContainer.handleClick}
					results={sortedIndividualResults}
					resultsForLength={Results.length}
					handlePaginationClick={this.handlePaginationClick}
					startIndex={startPaginationIndex}
				/> : null}


				{Results ? <OrganisationResults
					handleClick={FormContainer.handleClick}
					results={sortedOrganisationResults}
					resultsForLength={Results.length}
					handlePaginationClick={this.handlePaginationClick}
					startIndex={startPaginationIndex}
				/> : null}
				<div className="findACAInformation">
					<hr />
					<h6>Data Information</h6>
					<p>
						No reproduction, copy or transmission of this data may be made
						without written permission. Further, any person, company or firm
						found to be reproducing or assisting others in reproducing mailing
						lists, or machine-readable versions derived from this publication,
						will be prosecuted for copyright infringement.
					</p>
					<h6>Data Protection</h6>
					<p>
						The personal data published in the directory allows ICAS to fulfil
						its legitimate interests as a professional body and regulator of
						accountants. It is also required for the performance of tasks which
						are carried out in the public interest. ICAS is fully committed to
						handling personal data in accordance with data protection
						legislation and best data protection practices. Please review our{" "}
						<a
							href="https://www.icas.com/privacy"
							rel="noopener noreferrer"
							target="_blank"
						>
							privacy notice
						</a>{" "}
						for more information
					</p>
				</div>
			</div>
		);
	}
}
