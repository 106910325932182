import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function OrganisationForm (props) {

	const [form, setForm] = useState({
		companyName: "",
		location: "",
		iPFirm: false,
		capsFirm: false,
		investmentBAFirm: false,
		auditRegisteredFirm: false
	})

	const validateFormInput = ({target}) => {
		const input = target;
		let validate = true;
		const button = document.getElementById("submitFirmButton");

		if (input.name === "companyName") {
			if (
				input.value.length === 0 ||
				(input.value.length >= 2 && /^\D+$/.test(input.value))
			) {
				validate = true;
			} else {
				validate = false;
			}
		} else if (input.name === "location") {
			if (!input.value.length || input.value.length >= 2) {
				validate = true;
			} else {
				validate = false;
			}
		}
		if (!validate) {
			button.disabled = true;
			return input.classList.add("is-invalid");
		}
		button.disabled = false;
		if (input.classList.contains("is-invalid")) {
			input.classList.remove("is-invalid");
		}
		return validate;
	}

	const handleChange = ({target}) => {
		const {name, value} = target;
		setForm(prevState => ({ ...prevState, [name]: value}));

	}

	const handleClick = ({target}) => {
		const { name, checked }  = target;
		setForm(prevState => ({ ...prevState, [name]: checked}));
	}

	const { submit } = props;
	const { CompanyName, Location } = form;
		return (
			<Form
				id="ca-member-organisation-directory-form"
				className="optionForm"
				onSubmit={submit}
				aria-label="CA Member Organisation Search Form"
			>
				<h2 id="ca-member-organisation-directory-form-title" style={{ fontWeight: "300" }}>CA Member Organisation Directory</h2>
				<h5 className="hiddenError" id="hiddenFirmErrorMessage">
					* Please search by at least two criteria *
				</h5>
				<Form.Group controlId="companyName">
					<Form.Label>Company Name</Form.Label>
					<Form.Control
						type="text"
						name="companyName"
						value={CompanyName}
						onChange={handleChange}
						onBlur={validateFormInput}
					/>
					<Form.Control.Feedback type="invalid">
						Please input at least two letters for a search request
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="location">
					<Form.Label>City/Postcode</Form.Label>
					<Form.Control
						type="text"
						name="city/postcode"
						value={Location}
						onChange={handleChange}
						onBlur={validateFormInput}
					/>
					<Form.Control.Feedback type="invalid">
						Please input at least two letters for a search request
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group
					controlId="auditRegisteredFirm"
					aria-label="Registered by ICAS for Audit Work checkbox container"
				>
					<Form.Check
						type="checkbox"
						label="Registered by ICAS for Audit Work"
						onClick={handleClick}
					/>
				</Form.Group>
				<Form.Group
					controlId="investmentBAFirm"
					aria-label="Regulated by ICAS for a range of investment business activities checkbox container"
				>
					<Form.Check
						style={{ marginTop: "2%" }}
						type="checkbox"
						label="Regulated by ICAS for a range of investment business activities"
						onClick={handleClick}
					/>
				</Form.Group>
				<Form.Group
					controlId="ipFirm"
					aria-label="CA firm has ICAS licensed Insolvency Practioners checkbox container"
				>
					<Form.Check
						style={{ marginTop: "2%" }}
						type="checkbox"
						label="CA firm has ICAS licensed Insolvency Practioners"
						onClick={handleClick}
					/>
				</Form.Group>
			
				<Button
					variant="main"
					id="submitFirmButton"
					disabled={false}
					type="submit"
				>
					Submit
				</Button>
			</Form>
		);
	}

	export default OrganisationForm;

