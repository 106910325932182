import React from "react";
import Card from "react-bootstrap/Card";
import "./results.css";
import ReactPaginate from "react-paginate";

const IndividualResults = ({
	handleClick,
	handlePaginationClick,
	results,
	resultsForLength,
	startIndex,
}) => {
	if (!results) {
		return null;
	}

	let pagination;

	const resultsStatement =
		resultsForLength > 5
			? `Displaying ${startIndex + 1} - ${
				startIndex + 5 > resultsForLength ? resultsForLength : startIndex + 5
			  } of ${resultsForLength} results found for your enquiry.`
			: `Displaying ${resultsForLength} results for your enquiry.`;

	const resultsSorted = results.map((result, index) => {
		const keyIndex = index + 1;
		const town = result.Town.toLowerCase()
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
		const displayTown =
			result.PreferredAddress.Address1 === "c/o ICAS - CA House"
				? "C/O ICAS"
				: town;
		return (
			<Card
				key={keyIndex}
				id={`${index  }Card`}
				border="none"
				aria-label={`CA individual result for ${result.Name} in ${displayTown}`}
			>
				<Card.Header
					id={index}
					onClick={handleClick}
					aria-label="result header"
				>
					<Card.Title aria-label="result name">
						{result.Prefix} {result.FirstName} {result.MiddleName}{" "}
						{result.LastName} {result.DesignatoryLetters}
					</Card.Title>
					<Card.Text aria-label="result member type">
						{result.MemberType}
					</Card.Text>
					<Card.Text aria-label="result town">{displayTown}</Card.Text>
				</Card.Header>
				<Card.Body aria-label="result body" className="hidden toggleText">
					{displayTown !== "C/O ICAS" && (
						<Card.Text aria-label="individial result firm">
							<b>Firm:</b> {result.FirmName}{" "}
						</Card.Text>
					)}
					<Card.Text aria-label="address header">
						<b>Address:</b>
					</Card.Text>
					<Card.Text aria-label="address line one">
						{result.PreferredAddress.Address1}
					</Card.Text>
					<Card.Text aria-label="address line two">
						{result.PreferredAddress.Address2}
					</Card.Text>
					<Card.Text aria-label="town">{displayTown}</Card.Text>
					<Card.Text aria-label="postcode">
						{result.PreferredAddress.PostalCode}
					</Card.Text>
				</Card.Body>
			</Card>
		);
	});

	if (resultsForLength > 5) {
		pagination = (
			<ReactPaginate
				previousLabel="Previous"
				nextLabel="Next"
				breakLabel="..."
				breakClassName="break-me"
				marginRangeDisplayed={2}
				pageRangeDisplayed={3}
				pageCount={resultsForLength / 5}
				containerClassName="pagination"
				subContainerClassName="pages pagination"
				activeClassName="active"
				onPageChange={handlePaginationClick}
			/>
		);
	}

	return (
		<div className="resultsContainer">
			<h5>{resultsStatement}</h5>
			<h6>Please select a result to show further information.</h6>
			{resultsSorted}
			{pagination}
		</div>
	);
};

export default IndividualResults;
