import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function IndividualForm(props) {

	const [form, setForm] = useState({
		forename: "",
		surname: "",
		location: "",
		ipCheckbox: false,
		pcCheckbox: false,
	})

	const validateFormInput = ({ target }) => {
		const input = target;
		let validate = true;
		const button = document.getElementById("submitButton");

		if (input.name === "forename" || input.name === "surname") {
			validate =
				(!input.value.length ||
					(input.value.length >= 2 && /^\D+$/.test(input.value)));
		} else if (input.name === "location") {
			validate = (!input.value.length || input.value.length >= 2);
		}
		if (!validate) {
			button.disabled = true;
			return input.classList.add("is-invalid");
		}
		button.disabled = false;
		if (input.classList.contains("is-invalid")) {
			return input.classList.remove("is-invalid");
		}
		return validate;
	}

	const handleChange = ({ target }) => {
		const { name, value } = target;
		setForm(prevState => ({ ...prevState, [name]: value }));

	}

	const handleClick = ({ target }) => {
		const { name, checked } = target;
		setForm(prevState => ({ ...prevState, [name]: checked }));
	}


	const { submit } = props;
	const { forename, surname, location } = form;
	return (
		<Form
			id="ca-member-directory-form"
			className="optionForm"
			aria-label="CA Individual Member Search"
			onSubmit={submit}
			validated={false}
		>
			<h2 id="ca-member-directory-form-title" style={{ fontWeight: "300" }}>CA Member Directory</h2>
			<h5 className="hiddenError" id="hiddenErrorMessage">
				* Please search by at least two criteria *
				</h5>
			<Form.Group controlId="forename" role="presentation">
				<Form.Label>Forename</Form.Label>
				<Form.Control
					type="text"
					name="forename"
					aria-label="ca member first name"
					value={forename}
					onChange={handleChange}
					onBlur={validateFormInput}
				/>
				<Form.Control.Feedback type="invalid">
					Please input at least two letters for your search request
					</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="surname" role="presentation">
				<Form.Label>Surname</Form.Label>
				<Form.Control
					type="text"
					name="surname"
					aria-label="ca member surname"
					value={surname}
					onChange={handleChange}
					onBlur={validateFormInput}
				/>
				<Form.Control.Feedback type="invalid">
					Please input at least two letters for your search request
					</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="location" role="presentation">
				<Form.Label>City/Postcode</Form.Label>
				<Form.Control
					type="text"
					name="location"
					aria-label="ca member city or postcode"
					value={location}
					onChange={handleChange}
					onBlur={validateFormInput}
				/>
				<Form.Control.Feedback type="invalid">
					Please input at least two letters for your search request
					</Form.Control.Feedback>
			</Form.Group>
			<Form.Group
				controlId="ipCheckbox"
				role="presentation"
				aria-label="Member holds an Insolvency Permit issued by ICAS checbox container"
			>
				<Form.Check
					type="checkbox"
					label="Member holds an Insolvency Permit issued by ICAS"
					onClick={handleClick}
				/>
			</Form.Group>
			<Form.Group
				controlId="pcCheckbox"
				role="presentation"
				aria-label="Member holds a Practising Certificate issued by ICAS checkbox container"
			>
				<Form.Check
					style={{ marginTop: "2%" }}
					type="checkbox"
					label="Member holds a Practising Certificate issued by ICAS"
					onClick={handleClick}
				/>
			</Form.Group>
			<Button variant="main" id="submitButton" disabled={false} type="submit">
				Submit
				</Button>
			<br />
		</Form>
	);
}

export default IndividualForm;
