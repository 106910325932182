import React from "react";
import Card from "react-bootstrap/Card";
import "./results.css";
import ReactPaginate from "react-paginate";

const OrganisationResults = ({
	handleClick,
	handlePaginationClick,
	results,
	resultsForLength,
	startIndex,
}) => {
	if (!results) {
		return null;
	}

	let pagination;
	const resultsLength = resultsForLength;

	const resultsStatement =
		resultsLength > 5
			? `Displaying ${startIndex + 1} - ${
				startIndex + 5 > resultsForLength ? resultsForLength : startIndex + 5
			  }  of ${resultsLength} results found for your enquiry.`
			: `Displaying ${resultsLength} results for your enquiry.`;

	const resultsSorted = results.map((result, index) => {
		const key = index + 1;
		const town = result.Town.toLowerCase()
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
		const partners = result.WorkAddress.Partners || [];
		const email = result.Email2 || result.Email;

		return (
			<Card
				key={key}
				id={`${index  }Card`}
				border="none"
				aria-label={`CA organisation result for ${result.Name}in ${town}`}
			>
				<Card.Header
					aria-label="result header"
					id={index}
					onClick={handleClick}
				>
					<Card.Title aria-label="organisation name">{result.Name}</Card.Title>
					<Card.Text aria-label="organisation town">{town}</Card.Text>
				</Card.Header>
				<Card.Body aria-label="result body" className="hidden toggleText">
					<Card.Text aria-label="Organisation Address">
						<b>Address:</b> {result.WorkAddress.Address1}{" "}
						{result.WorkAddress.Address2}, {town},{" "}
						{result.WorkAddress.PostalCode}{" "}
					</Card.Text>
					{result.Website && (
						<Card.Text aria-label="Organisation Website">
							<b>Website:</b>
							<a href={result.Website}> {result.Website}</a>
						</Card.Text>
					)}
					{email && (
						<Card.Text aria-label="Organisation Email">
							<b>Email:</b>
							<a href={`mailto: ${email}`}> {email}</a>
						</Card.Text>
					)}
					{result.WorkAddress.Phone && (
						<Card.Text aria-label="Organisation Phone">
							<b>Phone:</b>
							<a href={`tel: ${result.WorkAddress.Phone}`}>
								{" "}
								{result.WorkAddress.Phone}
							</a>
						</Card.Text>
					)}
					{partners.length > 0 && (
						<Card.Text aria-label="Organisation Partners">
							<b>Principal(s) - ICAS members and affiliates only:</b>
						</Card.Text>
					)}
					{partners.map((partner, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<Card.Text aria-label="Organisation ICAS Partner" key={i}>
								{partner.FullName}
							</Card.Text>
						))}
				</Card.Body>
			</Card>
		);
	});

	if (resultsLength > 5) {
		pagination = (
			<ReactPaginate
				previousLabel="Previous"
				nextLabel="Next"
				breakLabel="..."
				breakClassName="break-me"
				marginRangeDisplayed={2}
				pageRangeDisplayed={3}
				pageCount={resultsLength / 5}
				containerClassName="pagination"
				subContainerClassName="pages pagination"
				activeClassName="active"
				onPageChange={handlePaginationClick}
			/>
		);
	}

	return (
		<div
			className="resultsContainer"
			name="CA Member Organisation Results Container"
		>
			<h5>{resultsStatement}</h5>
			<h6>Please select a result to show further information.</h6>
			{resultsSorted}
			{pagination}
		</div>
	);
};

export default OrganisationResults;
